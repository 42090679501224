import React, { useState, useEffect } from 'react';
import './Footer.css';
import { Link, useLocation } from 'react-router-dom';
import arrow_up_right from '../../assets/Arrow-Up-Right.svg'

const Footer = ({children, MarginTopDesktop, MarginTopMobile}) => {
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [MarginTop, setMarginTop] = useState(MarginTopDesktop);
 
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        //Cleanup event listener on unmount
        return () => window.removeEventListener("resize", handleResize); 

    }, []);

    useEffect(() => {
        if (windowWidth <= 768) {
            //for mobile view
            setMarginTop(MarginTopMobile);
        } else {
            //for desktop view
            setMarginTop(MarginTopDesktop);
        }
    }, [windowWidth]);

    const location = useLocation();
    const isCurrentPage = (path) => location.pathname === path;

    return (
        <div style={{marginTop : MarginTop}} className="Footer">
            <div className="Navigation">
                <nav>
                    <Link to="/" className={isCurrentPage("/") ? "CurrentPage" : "AboutUs"}>Home</Link>
                    <Link to="/about-us" className={isCurrentPage("/about-us") ? "CurrentPage" : 'AboutUs'}>About us</Link>
                    <Link to="/contact" className={isCurrentPage("/contact") ? "CurrentPage" : "AboutUs"}>Contact us</Link>
                    <Link to="/sign-me-up" className={isCurrentPage("/sign-me-up") ? "CurrentPage" : "_EarlyAccess"}>Early Access?</Link>
                    <a className="_SocialHover" target="_blank" href="https://twitter.com/WeAreSimli">X (Twitter)<img src={arrow_up_right} alt=""/></a>
                    <a className="_SocialHover" target="_blank" href="https://www.linkedin.com/company/simli">LinkedIn<img src={arrow_up_right} alt=""/></a>
                    <Link to="/privacy-policy" className={isCurrentPage("/privacy-policy") ? "CurrentPage" : "PrivacyPolicy"}>Privacy Policy</Link>

                </nav>
                <img style={{marginBottom:'24px'}} className='PoweredBy' alt="" />
            </div>
            {children}
        </div>
    );
};

export default Footer;

