import React, { useState, useEffect } from 'react';
import './CookiesConsent.css';
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import X from '../../assets/Close-Icon-Black.svg'

const setCookie = (name, value, daysToLive) => {
    let expires = "";
    if (daysToLive) {
        const date = new Date();
        date.setTime(date.getTime() + (daysToLive * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
};

const getCookie = (name) => {
    let cookieArray = document.cookie.split(';');
    for(let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name + "=") === 0) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return "";
};
const CookieHeading = styled.h2`
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
`;
const CustomP = styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word;
`;
const HyperLink = styled.u`
    color: #00F;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word;
`;

const CookieConsent = () => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
      const consent = getCookie('userConsent');
      if (consent === 'accepted' || consent === 'declined') {
        setVisible(false);
      }
    }, []);
  
    const handleAccept = () => {
      setCookie('userConsent', 'accepted', 365); // Sets a cookie for 1 year
      setVisible(false);
      console.log('accepted');
    };
  
    const handleDecline = () => {
      setCookie('userConsent', 'declined', 365); // Sets a cookie for 1 year
      setVisible(false);
      console.log('declined');
    };
  
    if (!visible) return null;
  

  return (
    <div className="CookieConsent">
      <div className="CookieContent">
        <div className="CookieHeader">
          <CookieHeading className='Cookie'>Cookies🍪</CookieHeading><img onClick={handleDecline} style={{color:'blue'}} className='CloseIcon' src={X} alt=""/>
        </div>
        <CustomP>This website stores cookies on your computer. These cookies are used to improve your website experience and provide more personalized services to you, both on this website and through other media. To find out more about the cookies we use, see our <Link to= '/privacy-policy'><HyperLink>Privacy Policy</HyperLink></Link>.</CustomP> 
        
        <CustomP>If you decline we won’t track your information when you visit our site. But in order to comply with your preferences, we’ll have to use just one tiny cookie so that you’re not asked to make this choice again.</CustomP>

        <div className="CookieButtons">
          <button onClick={handleAccept} className="Button16px _Fill _ButtonSize6">Accept</button>
          <button onClick={handleDecline} className="DeclineButton"><b>Decline</b></button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
