import React from 'react';
import close_icon from '../assets/Close-Icon.svg';
import { useNavigate } from 'react-router-dom';

const CloseButton = () => {
    const navigate = useNavigate();
// Get the last visited path

    const goBack = () => {
        navigate(-1);
    };

    return (
        <button className="Button _Hollow _ButtonSize3" onClick={goBack}>
            Close<img src={close_icon} alt=""></img>
        </button>
    );
};

export default CloseButton;
