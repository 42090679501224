import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScreenLanding from './screens/landing/landing';
import ScreenEarlyAccessForm from './screens/early-access-form/early-access-form';
import ScreenPrivacyPolicy from './screens/privacy-policy/privacy-policy';
import ScreenSanta from './screens/santa/santa';
import ScreenAboutUs from './screens/about-us/about-us';
import ScreenContact from './screens/contact/contact';
import ScreenDemo from './screens/demo/demo';
// import ScreenContactUs from './screens/contact-us/contact-us';
import getDeploymentEnvironment from './envConfig';
import ScrollToTop from './components/ScrollToTop.js';
import { NavigationProvider } from './NavigationContext.js'; // Import the provider


// hello2
function App() {
  const env = getDeploymentEnvironment();

  if (env === 'master') {
    // Production-specific logic
    console.log('master');
  } else {
    // Development-specific logic
    console.log('dev');
  }
  return (
      <Router>
        <NavigationProvider>
          <div>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<ScreenLanding />} />
              <Route path="/sign-me-up" element={<ScreenEarlyAccessForm />} />
              <Route path="/privacy-policy" element={<ScreenPrivacyPolicy />} />
              <Route path="/santa" element={<ScreenSanta />} />
              <Route path="/about-us" element={<ScreenAboutUs />} />
              <Route path="/contact" element={<ScreenContact />} />
              <Route path="/demo" element={<ScreenDemo />} />
            </Routes>
          </div>
        </NavigationProvider>
      </Router>
  );
}

export default App;
