import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Cookies from "../../components/CookiesConsent/CookiesConsent";
import jennatalking from '../../assets/Jenna_talking.mp4'
import './landing.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import santaface from '../../assets/santa face.png';
import arrow_right from '../../assets/Arrow-Right.svg';

const ScreenLanding = () => {
    const location = useLocation();

    useEffect(() => {
        // Reset logo style here
        const logo = document.querySelector('.Logo');
        if (logo) {
          logo.style.filter = 'none'; // Reset the filter
        }
      }, [location]);

    return (
        <div className="LandingScreen">

            {/* Video Background */}
            <video src={jennatalking} autoPlay muted loop controls={false} playsInline className="VideoBackground" />

            {/* Background */}
            <div className="BackgroundColor" />

            {/* Header */}
            <Header LeftSide={0} RightSide={1} TextButton = 'Hello!'></Header>

            {/* Early access */}
            <div className="LandingMain">
                <div>
                    <h1>Real-time.</h1>
                    <h1>Unscripted.</h1>
                    <h1 style={{marginBottom: '24px'}}>Human-like.</h1>
                </div>
                <p>Our end-to-end API lets you generate video conversations with AI avatars. Launching in March.</p>
                <Link to="/sign-me-up" className="Link">
                <button className="Button _Fill _ButtonSize1">Early access?</button>
                </Link>
            </div>
            <Cookies />
            {/* Footer */}
                <Footer MarginTopDesktop={'280px'} MarginTopMobile={'192px'}>
                    {/* 
                    <div className="SantaHijack">
                        <a href="/santa">
                            <img src={santaface} className='SantaFace' alt="Santa" />
                        </a>
                        <a href="/santa" className="Link">
                            <button className="Button _Hollow _ButtonSize2">🎅🏻 Santa’s hijack <img src={arrow_right} alt="" /></button>
                        </a>
                    </div> */}
                </Footer>                   
        </div>
    );
};

export default ScreenLanding;