import logo from '../../svgs/simli_logo.svg';
import './early-access-form.css'
import exclamation from '../../assets/exclamation.svg'
import close_icon from '../../assets/Close-Icon.svg'
import arrow_up_right from '../../assets/Arrow-Up-Right.svg'
import React, { useState } from 'react';
import HubspotForm from 'react-hubspot-form'
import { Link } from 'react-router-dom';
import CloseButton from '../../components/CloseButton';
import { useEffect } from 'react';
import styled from 'styled-components'
import Header from '../../components/Header/Header';

const CustomABCReproBold = styled.p`
    color: var(--White, #FFF);

    /* Body Bold */
    font-family: "ABCRepro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 162.5% */
    margin-bottom: 0px;
`;

const ScreenEarlyAccessForm = () => {
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitFailed, setSubmitFailed] = useState(false);
    const [response, setResponse] = useState(null);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);

        // Validate the email using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValid(emailRegex.test(inputEmail));
    };

    const handleSubmit = async () => {
        if (isValid && isChecked) {
            setIsSubmitted(true);

            // Send the email to your API endpoint
            try {

                const url = "https://forms-eu1.hsforms.com/submissions/v3/public/submit/formsnext/multipart/25118831/53124343-67d2-4ca4-99c7-f49b8391efd4";

                const headers = {
                    'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
                    'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8',
                    'Cache-Control': 'no-cache',
                    'Connection': 'keep-alive',
                    // 'Content-Type': 'multipart/form-data', // This will be set automatically when using FormData
                    'Origin': 'http://localhost:3000',
                    'Pragma': 'no-cache',
                    'Sec-Fetch-Dest': 'iframe',
                    'Sec-Fetch-Mode': 'navigate',
                    'Sec-Fetch-Site': 'cross-site',
                    'Sec-Fetch-User': '?1',
                    'Upgrade-Insecure-Requests': '1',
                    'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36',
                    'sec-ch-ua': '"Chromium";v="116", "Not)A;Brand";v="24", "Google Chrome";v="116"',
                    'sec-ch-ua-mobile': '?0',
                    'sec-ch-ua-platform': '"macOS"',
                };

                const hs_context = {
                    "embedAtTimestamp":"1704450992426",
                    "formDefinitionUpdatedAt":"1703068488318",
                    "lang":"en",
                    "legalConsentOptions":"{\"legitimateInterestSubscriptionTypes\":[121448229],\"communicationConsentCheckboxes\":[{\"communicationTypeId\":121448229,\"label\":\"<p>By checking this box, you agree to receive marketing and sales communications from Simli.</p>\",\"required\":true}],\"legitimateInterestLegalBasis\":\"LEGITIMATE_INTEREST_PQL\",\"processingConsentType\":\"IMPLICIT\",\"processingConsentCheckboxLabel\":\"I agree to allow Yatek to store and process my personal data.\",\"isLegitimateInterest\":false}",
                    "embedType":"REGULAR",
                    "userAgent":"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
                    "pageTitle":"Simli Studio","pageUrl":"http://localhost:3000/sign-me-up","isHubSpotCmsGeneratedPage":false,
                    "formTarget":"#hbspt-form-8718172e-cacc-4cd9-b9a9-b10391960ae8",
                    "boolCheckBoxFields":"LEGAL_CONSENT.subscription_type_121448229",
                    "rumScriptExecuteTime":70.5,"rumTotalRequestTime":80.19999998807907,"rumTotalRenderTime":95.30000001192093,"rumServiceResponseTime":9.699999988079071,"rumFormRenderTime":15.100000023841858,"locale":"en","timestamp":1704451030837,"originalEmbedContext":{"portalId":"25118831","formId":"53124343-67d2-4ca4-99c7-f49b8391efd4","region":"eu1","target":"#hbspt-form-8718172e-cacc-4cd9-b9a9-b10391960ae8","isBuilder":false,"isTestPage":false,"isPreview":false,"isMobileResponsive":true,"isInsideFrame":true,"shellId":1,"pageTitle":"Simli Studio","pageUrl":"http://localhost:3000/sign-me-up","referrer":""},"correlationId":"8718172e-cacc-4cd9-b9a9-b10391960ae8","renderedFieldsIds":["email","LEGAL_CONSENT.subscription_type_121448229"],"captchaStatus":"NOT_APPLICABLE","emailResubscribeStatus":"NOT_APPLICABLE","isInsideCrossOriginFrame":false,"source":"forms-embed-1.4371","sourceName":"forms-embed","sourceVersion":"1.4371","sourceVersionMajor":"1","sourceVersionMinor":"4371","allPageIds":{},"_debug_embedLogLines":[{"clientTimestamp":1704450992534,"level":"INFO","message":"Retrieved pageContext values which may be overriden by the embed context: {\"pageTitle\":\"Simli Studio\",\"pageUrl\":\"http://localhost:3000/sign-me-up\",\"userAgent\":\"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36\",\"isHubSpotCmsGeneratedPage\":false}"},{"clientTimestamp":1704450992535,"level":"INFO","message":"Retrieved countryCode property from normalized embed definition response: \"NO\""}]};;

                const formData = new FormData();
                formData.append("email", email); // Replace with your email variable
                formData.append("LEGAL_CONSENT.subscription_type_121448229", "true");
                formData.append("hs_context", JSON.stringify(hs_context));
                // Add other form fields as required

                const response = await fetch(url, {
                    method: 'POST',
                    headers: headers,
                    body: formData,
                    compress: true,
                });
        
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
        
                await response.text();
              } catch (error) {
                console.error('Error:', error);
              }
        }
        else {
            setSubmitFailed(true);
        }
    };


    return (isSubmitted ?
        (
            <div className="EarlyAccessForm-window">
                <div className='EarlyAccessForm-submitted-container'>
                    <div className="EarlyAccessForm-submitted">
                        <h3>You’ll hear from us as soon as we have some news 👏</h3>
                        <Link to="/" className='Link'>
                        <button className="Button _Fill _ButtonSize1">Great!</button>
                        </Link>
                        <nav>
                            <a className="_Social" target="_blank" href="https://twitter.com/WeAreSimli">X (Twitter)<img src={arrow_up_right} alt="" /></a>
                            <a className="_Social" target="_blank" href="https://www.linkedin.com/company/simli">LinkedIn<img src={arrow_up_right} alt="" /></a>
                        </nav>
                    </div>
                </div>
            </div>
        )
        :
        (
            <div className="EarlyAccessForm-window">
                <Header LeftSide={0} RightSide={2} Style={0}/>
                <div className="EarlyAccessForm-form-container">
                    <div className="EarlyAccessForm-form">
                        <CustomABCReproBold>Enter e-mail</CustomABCReproBold>
                        <input
                            className={`CustomInput ${isActive ? '_Active' : (isValid ? '_Valid' : (submitFailed ? '_Invalid' : ''))}`}
                            type="email"
                            placeholder="name@example.com"
                            value={email}
                            onFocus={() => setIsActive(true)}
                            onBlur={() => setIsActive(false)}
                            onChange={handleEmailChange}
                        />
                        {/* <HubspotForm
                            portalId='25118831'
                            formId='53124343-67d2-4ca4-99c7-f49b8391efd4'
                            onSubmit={() => console.log('Submit!')}
                            onReady={(form) => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                        /> */}
                        <div className="Consent">
                            <label className="container">
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange} />
                                <span className={`checkmark ${submitFailed && !isChecked ? '_Invalid' : ''}`} />
                            </label>
                            <p>I consent to Simli contacting me via      e-mail and they may use the e-mail I have provided in the input field.</p>
                        </div>
                        <button className="Button _Fill _ButtonSize2" onClick={handleSubmit}>Sign me up</button>
                    </div>
                </div>
            </div>
        )
    );
};

export default ScreenEarlyAccessForm;