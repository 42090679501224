import logo_back from '../../svgs/simli_ArrowBack.svg';
import './santa.css';
import santaImg from '../../svgs/santa.png';
import santaVideo from '../../svgs/Blinking_4.mp4'
import React, { useState } from 'react';
import Header from "../../components/Header/Header";
import ArrowLeft from '../../assets/Arrow-Left.svg'
import { Link } from 'react-router-dom';


const ScreenSanta = () => {
  return (
    <div className='SantaScreen'>
      {/* Santa video */}
      <div className='SantaContainer'>
        <img src={santaImg} className='SantaImg' alt="santa" />
        <video src={santaVideo} autoPlay muted loop controls={false} playsInline />
      </div>

      {/* Main */}
      <div className='Main'>
        {/* <div className='snowfall-container'>
          <Snowfall />
        </div> */}
        <div className='Top'>
          {/* Header */}
            {/* Header */}
            <Header LeftSide={3} RightSide={1} Style = {5} TextButton = 'Hello!'/>

            <div className='SantaBubbleContainer'>
              <div className='SantaBubble'>
                <div className='Text'>
                  <p><b>Ho-ho-ho-no 🌴</b></p>
                  <p>Usually i hijack peoples video meetings, but I'm on vacation and will be back sometime next year ❤️</p>
                </div>
              </div>
              <div className='Triangle'></div>
            </div>
                    {/* Footer */}

        </div>
        <footer>
            <div className='BookButton'>
              <b>Santa’s on vacation 🏝️</b>
            </div>
            <div className='TimeSlotsLeft'>
              0 timeslots left
            </div>
          </footer>

      </div>
    </div>
  );
};

const HelloButton = () => {
  const [randomStyle, setRandomStyle] = useState(
    { backgroundColor: '#FF0000', borderRadius: '50%', color: 'white' } // Style 5
  );

  const handleClick = () => {
    setRandomStyle(getRandomStyle());
  };

  return (
    <button
      className='HelloButton'
      style={randomStyle}
      onClick={handleClick}
    >
      <b>Hello!</b>
    </button>
  );
};

// Global Index of the current HelloButton style
let HelloButtonIndex = 0;

// Function to generate pick a random style for the HelloButton
const getRandomStyle = () => {

  // Styles to choose from
  const styleList = [
    { backgroundColor: '#FFFF00', borderRadius: '50%' }, // Style 1
    { backgroundColor: '#00FF00', borderRadius: '0' }, // Style 2
    { backgroundColor: '#00FFF0', borderRadius: '100px' }, // Style 3
    { backgroundColor: '#FF00FF', borderRadius: '8px' }, // Style 4
    { backgroundColor: '#FF0000', borderRadius: '50%', color: 'white' }, // Style 5
    { backgroundColor: '#B198F6', borderRadius: '0' }, // Style 6
    { backgroundColor: '#8900DA', borderRadius: '0', color: 'white' },  // Style 7
  ]

  HelloButtonIndex++;

  return styleList[HelloButtonIndex % 7];
};


export default ScreenSanta;