import './VideoControls.css';
import phone_img from '../../../../assets/Phone.svg';
import pause_img from '../../../../assets/pause.svg';


export default function VideoControls({ pauseCall, leaveCall }) {
  return (
    <div className="video-controls-container">
        <button className="Button _Fill _ButtonSize2 pause_button" onClick={pauseCall}>Pause<img src={pause_img}/></button>
        <button className="Button _Fill _ButtonSize2 leave_button" onClick={leaveCall}>Leave<img src={phone_img}/></button>
    </div>
  );
}