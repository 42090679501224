import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
    const [lastPath, setLastPath] = useState('');
    const location = useLocation();

    useEffect(() => {
        setLastPath(location.pathname);
    }, [location]);

    return (
        <NavigationContext.Provider value={{ lastPath }}>
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavigation = () => useContext(NavigationContext);
