import './contact.css'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import arrow from '../../assets/Arrow-Right.svg';
import styled from 'styled-components'
import React, { useState } from 'react';

const CustomABCRepro = styled.p`
    color: var(--White, #FFF);

    /* Body */
    font-family: "ABCRepro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
`;

const CustomABCReproBold = styled.p`
    color: var(--White, #FFF);

    /* Body Bold */
    font-family: "ABCRepro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 162.5% */
    margin-bottom: 3px;
`;

const ScreenContact = () => {

    const [isSent, setIsSent] = useState(false);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');

    const [isEmailActive, setIsEmailActive] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);

    const [isNameActive, setIsNameActive] = useState(false);
    const [isNameInvalid, setIsNameInvalid] = useState(false);

    const [isMessageActive, setIsMessageActive] = useState(false);
    const [isMessageInvalid, setIsMessageInvalid] = useState(false);

    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);

        // Validate the email using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsEmailValid(emailRegex.test(inputEmail));
    };

    const handleNameChange = (e) => {
        const inputName = e.target.value;
        setName(inputName);
    };

    const handleMessageChange = (e) => {
        const inputMessage = e.target.value;
        setMessage(inputMessage);
    };

    const handleSend = async () => {
        if (!isEmailValid) setIsEmailInvalid(true);

        if (isEmailValid && name != '' && message != '') {
            try {
                const url = "https://forms-eu1.hsforms.com/submissions/v3/public/submit/formsnext/multipart/25118831/42528ed8-f915-4a0f-ae10-af2daf19de3f/json?hs_static_app=forms-embed&hs_static_app_version=1.4455&X-HubSpot-Static-App-Info=forms-embed-1.4455";

                const headers = {
                    "Accept": "application/json, text/plain, */*",
                    "Accept-Encoding": "gzip, deflate, br",
                    "Accept-Language": "en-US,en;q=0.9,ar;q=0.8",
                    "Connection": "keep-alive",
                    "Content-Length": "2424",
                    "Host": "forms-eu1.hsforms.com",
                    "Origin": "https://share-eu1.hsforms.com",
                    "Referer": "https://share-eu1.hsforms.com/",
                    "Sec-Fetch-Dest": "empty",
                    "Sec-Fetch-Mode": "cors",
                    "Sec-Fetch-Site": "same-site",
                    "User-Agent": "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Mobile Safari/537.36 Edg/120.0.0.0",
                    "sec-ch-ua": '"Not_A Brand";v="8", "Chromium";v="120", "Microsoft Edge";v="120"',
                    "sec-ch-ua-mobile": "?1",
                    "sec-ch-ua-platform": "Android",

                };

                const hs_context = {
                    "embedAtTimestamp": "1704982280262",
                    "formDefinitionUpdatedAt": "1704980407074",
                    "lang": "en",
                    "embedType": "REGULAR",
                    "clonedFromForm": "53124343-67d2-4ca4-99c7-f49b8391efd4",
                    "userAgent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36 Edg/120.0.0.0",
                    "pageTitle": "Form",
                    "pageUrl": "https://share-eu1.hsforms.com/1QlKO2PkVSg-uEK8trxnePweydtb",
                    "isHubSpotCmsGeneratedPage": false,
                    "formTarget": "#form-target",
                    "rumScriptExecuteTime": 1367.5999999977648,
                    "rumTotalRequestTime": 1938.0999999977648,
                    "rumTotalRenderTime": 2000.199999999255,
                    "rumServiceResponseTime": 570.5,
                    "rumFormRenderTime": 62.100000001490116,
                    "locale": "en",
                    "timestamp": 1704982330649,
                    "originalEmbedContext": {
                        "portalId": "25118831",
                        "formId": "42528ed8-f915-4a0f-ae10-af2daf19de3f",
                        "region": "eu1",
                        "target": "#form-target",
                        "isBuilder": false,
                        "isTestPage": false,
                        "isPreview": false,
                        "isMobileResponsive": true,
                        "pageUrl": "https://share-eu1.hsforms.com/1QlKO2PkVSg-uEK8trxnePweydtb",
                        "__INTERNAL__CONTEXT": {
                            "editorVersion": "1.0"
                        }
                    },
                    "correlationId": "6a7f0703-9121-4e16-9da5-e6ea8ddcd98a",
                    "renderedFieldsIds": [
                        "email",
                        "firstname",
                        "message"
                    ],
                    "captchaStatus": "NOT_APPLICABLE",
                    "emailResubscribeStatus": "NOT_APPLICABLE",
                    "isInsideCrossOriginFrame": false,
                    "source": "forms-embed-1.4455",
                    "sourceName": "forms-embed",
                    "sourceVersion": "1.4455",
                    "sourceVersionMajor": "1",
                    "sourceVersionMinor": "4455",
                    "allPageIds": {

                    },
                    "_debug_embedLogLines": [
                        {
                            "clientTimestamp": 1704982305971,
                            "level": "INFO",
                            "message": "Retrieved pageContext values which may be overriden by the embed context: {\"pageTitle\":\"Form\",\"pageUrl\":\"https://share-eu1.hsforms.com/1QlKO2PkVSg-uEK8trxnePweydtb\",\"userAgent\":\"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36 Edg/120.0.0.0\",\"isHubSpotCmsGeneratedPage\":false}"
                        },
                        {
                            "clientTimestamp": 1704982306512,
                            "level": "INFO",
                            "message": "Retrieved countryCode property from normalized embed definition response: \"GB\""
                        }
                    ]
                };

                const formData = new FormData();
                formData.append("email", email);
                formData.append("firstname", name);
                formData.append("message", message);
                formData.append("LEGAL_CONSENT.subscription_type_121448229", "true");
                formData.append("hs_context", JSON.stringify(hs_context));

                const response = await fetch(url, {
                    method: 'POST',
                    headers: headers,
                    body: formData,
                    compress: true,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                await response.text();
                setIsSent(true);

            } catch (error) {
                console.log(error);
            }
        }

    };

    return (
        <div className='ContactScreen'>
            <Header LeftSide={3} RightSide={4} TextButton='Hello!' />
            <div className='Contact-flex'>
                <div className='Contact-info-flex'>
                    <div className='Contact-item'>
                        <h3>Get in touch!</h3>
                        <p className='text_gray'>We’re always open to chat to potential partners, users, investors, journalists or whoever you might be.</p>
                    </div>
                    <div className='Contact-item'>
                        <p className='text_gray'>Send us an e-mail</p>
                        {/* Open email provider */}
                        <a href='mailto: hello@simli.com' className='_mail'><h3>hello@simli.com</h3><img src={arrow} alt='' /></a>
                    </div>
                </div>
                <div className='Contact-form-flex Contact-item'>
                    <div>
                        <p className='text_gray'>or fill out the</p>
                        <div className='_contact-header'><h3>Contact form</h3><img src={arrow} alt='' /></div>
                    </div>
                    {
                        isSent ?
                            (
                                <div className='Centered'>
                                    <div className='Contact-form-submitted'>
                                        <b>Form sent and recieved. You’ll hear from us as soon 🤘</b>
                                    </div>
                                </div>
                            ) :
                            (
                                <div className='Contact-form-flex Contact-item'>
                                    {/* Input Email */}
                                    <div>
                                        <CustomABCReproBold>Your e-mail</CustomABCReproBold>

                                        <input
                                            style={{
                                                fontWeight: '400',
                                                fontFamily: 'ABCReproMono',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                lineHeight: '26px'
                                            }}
                                            className={`CustomInput ${isEmailActive ? '_Active' : (isEmailValid ? '_Valid' : (isEmailInvalid ? '_Invalid' : ''))}`}
                                            name="email"
                                            type="email"
                                            placeholder="name@example.com"
                                            value={email}
                                            onFocus={() => setIsEmailActive(true)}
                                            onBlur={() => setIsEmailActive(false)}
                                            onChange={handleEmailChange}
                                        />


                                    </div>

                                    {/* Input Name */}
                                    <div>
                                        <CustomABCReproBold>Name</CustomABCReproBold>
                                        <input
                                            style={{
                                                fontWeight: '400',
                                                fontFamily: 'ABCReproMono',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                lineHeight: '26px'
                                            }}
                                            className={`CustomInput ${isNameActive ? '_Active' : (name!='' ? '_Valid' : (isNameInvalid ? '_Invalid' : ''))}`}
                                            name="firstname"
                                            type="firstname"
                                            placeholder="Your name"
                                            value={name}
                                            onFocus={() => setIsNameActive(true)}
                                            onBlur={() => setIsNameActive(false)}
                                            onChange={handleNameChange}
                                        />
                                    </div>

                                    {/* Input Message */}
                                    <div>
                                        <CustomABCReproBold>Message</CustomABCReproBold>
                                        <textarea
                                            style={{
                                                fontWeight: '400',
                                                fontFamily: 'ABCReproMono',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                lineHeight: '26px'
                                            }}
                                            className={`CustomInput _message ${isMessageActive ? '_Active' : (message!='' ? '_Valid' : (isMessageInvalid ? '_Invalid' : ''))}`}
                                            name="message"
                                            type="message"
                                            placeholder="Your text"
                                            value={message}
                                            onFocus={() => setIsMessageActive(true)}
                                            onBlur={() => setIsMessageActive(false)}
                                            onChange={handleMessageChange}
                                        />
                                    </div>

                                    <button className='Button _Fill _ButtonSize2' onClick={handleSend}>Send</button>
                                </div>
                            )
                    }

                </div>

            </div>
            <Footer MarginTopDesktop={'84px'} MarginTopMobile={'164px'} />
        </div>
    );
};

export default ScreenContact;