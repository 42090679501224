import React from 'react';
import './ProfileCard.css'; // CSS Module import
import linkedin from '../../svgs/LinkedIn.svg';
import twitter from '../../svgs/X.svg';
import arrow_up_right from '../../assets/Arrow-Up-Right.svg'
import styled from 'styled-components'

const CustomA = styled.a`
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    word-wrap: break-word;
    color: #A9A9A9;
    gap: 8px;
    text-decoration-line: none;
`;

const ProfileCard = ({ image, name, description, twitterLink, linkedinLink }) => {
  return (
    <div className="card">
      <img src={image} alt={name} className="profileImage" />
      <h3 style={{marginBottom:'-2px'}} className="name">{name}</h3>
      <p className="description">{description}</p>
      <div className="buttonContainer">
      <CustomA className="_SocialHover" target="_blank" href={linkedinLink}>LinkedIn<img style={{width:'8px'}} src={arrow_up_right} alt=""/></CustomA>
      <CustomA className="_SocialHover" target="_blank" href={twitterLink}>X (Twitter)<img style={{width:'8px'}} src={arrow_up_right} alt=""/></CustomA>

      </div>
    </div>
  );
};

export default ProfileCard;
