import React from 'react';
import logo from '../../svgs/simli_logo.svg';
import './privacy-policy.css';
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import CloseButton from '../../components/CloseButton';
import Header from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';



const CustomH3 = styled.h3`
    color: #ffffff; /* White text */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    margin-bottom: 24px; /* Space below paragraphs */
`;
const CustomH4 = styled.h4`
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 24px;
`;
const CustomH6 = styled.h6`
    color: #ffffff; /* White text */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px; /* Space below paragraphs */
    word-wrap: break-word;
`;
const CustomP = styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word;
`;
const CustomU = styled.u`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-decoration-line: underline;
`;
const ScreenPrivacyPolicy = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };
    return (
        <div className="PrivacyPolicyScreen">
            <Header LeftSide={0} RightSide={2}/>
            <div className="PrivacyPolicyScreen-content">
                <CustomH3 style={{ marginBottom: '160px' }}><b></b></CustomH3>

                <CustomH3><b>Privacy Policy</b></CustomH3>
                <CustomP>This privacy policy provides information on how personal data is processed by Simli AS, organisation number 926 743 384 (“Simli”) when Simli is acting as a controller under the General Data Protection Regulation (“GDPR”).</CustomP>
                <CustomH6>All references to “we” and “us” refer to Simli. All references to “you” and “your” refer to the data subject.</CustomH6>

                <CustomH3 style={{ marginBottom: '24px' }}><b>1. The purpose of processing personal data</b></CustomH3>
                <CustomH4><b>1.1. Providing the Simli online training service</b></CustomH4>
                <CustomP style={{ marginBottom: '1.6px' }}>In order to provide our online training service, we will process personal data. The personal data we process depends on what data you submit, but normally includes the following: Information uploaded to the service: your CV/resume including information such as your name, telephone number, e-mail, address, profile picture, education, work experience, and skills.</CustomP>
                <CustomP>-Interaction data: transcript of content from the conversation with the mock interviewer. The information we collect is necessary for the service to function and to simulate realistic conversations. If you do not submit personal data to the service, the service might not function properly. Interaction data and content uploaded will be stored by us for up to 30 days with the purpose of ensuring compliance with the terms and conditions of the service. We use OpenAI OpCo, LLC as a data processor. They will temporarily store information you submit to the service for up to 30 days, for security reasons and in order to prevent misuse. The legal basis for the processing of personal data is that it is necessary to fulfill the agreement with you to provide the service according to article 6(1)(b) of the GDPR.</CustomP>
                <CustomH4><b>1.2. Processing personal data in order to respond to inquiries</b></CustomH4>
                <CustomP>In order to respond to inquiries, it is necessary to process personal data.We process personal data on customers, potential customers and visitors of our websites. The personal data may be e.g. the name and contact info of the person sending us inquiries, including the information in the inquiry. The legal basis for the processing of personal data is our legitimate interest according to article 6 (1)(f) of the GDPR. Our legitimate interests consist of being able to provide information about ourselves and our services upon request.</CustomP>
                <CustomH4><b>1.3. Processing personal data for marketing purposes</b></CustomH4>
                <CustomP>WWe process personal data when we are marketing our services. The purpose of the processing is to reach existing, potential and former customers and users in order to market our services. We process data such as name and contact information of the data subject, and data about the data subjects’ interests, interactions etc.The legal basis for the processing of personal data is either consent according to article 6 (1)(a) of the GDPR or our legitimate interest according to article 6(1)(f). When legitimate interest is the basis, we have considered that we have an interest in being able to market our services for business purposes, and that this interest is not overridden by the interest of the data subject.</CustomP>
                <CustomH4><b>1.4. Processing personal data on collaboration partners and contractors</b></CustomH4>
                <CustomP>We process personal data in connection with user testing of our services. The purposes are to recruit user testers, to carry out an agreement with the testers who have been chosen for participation and to enable user testers to actively engage in Simli’s virtual reality (VR) environments.
                    The data we process for these purposes includes the applicant's contact information such as name, telephone number and e-mail. To pay you the agreed fees we additionally process social security numbers and address of the user tester.
                    In the recruitment process we also ask if the applicant has experience with social anxiety, which means we are processing health data according to GDPR article 9. We ask for the applicant's consent to process this data. The purpose of collecting health data is to recruit user testers which represent the actual users of our products and services.
                    Our legal basis for the processing is GDPR article 6(1)(b) for performance of the agreement, and GDPR article 9 (2)(a) for processing of health data.</CustomP>
                <CustomH4><b>1.5. Cookies</b></CustomH4>
                <CustomH6>We use cookies on our services such as the Simli online training service. The cookies may process personal data such as IP-address. We use the following types of cookies:- Necessary cookies: We use certain cookies that are necessary for our services to function. The legal basis for this processing is our legitimate interest in providing a service that function.- Analytical and statistical cookies: To gain insight into the use of our services we use cookies provided by Google Analytics for analytical and statistical purposes. The legal basis for this processing is consent.Details about Google Analytics: We use Google Analytics to help us understand how our customers use our websites and services. Google Analytics is a web analytics service offered by Google. Google Analytics collects only the IP address assigned to you on the date you visit the website or service. This data may be shared with other Google services. More information about Google’s use of the information can be found here: <CustomP style={{ marginBottom: '0px' }}>https://policies.google.com/technologies/partner-sites</CustomP></CustomH6>

                <CustomH3><b>2. Storage time</b></CustomH3>
                <CustomH6>We will store your personal data as long as it is necessary to fulfil the purposes of the processing. When the purpose is fulfilled, we will delete or anonymise the data unless we have a new legal ground to process the data. This means for example:- We will store information about your use of the Simli online training service as long as you are actually using the service, and we will temporarily store information you have uploaded to prevent misuse. The information will be deleted after 30 days.- We will store information about your inquiries until they have been answered, and we do not believe your inquiry requires any further follow-up.- We will process data about user testers as long as they are performing user testing for us, and then as long as required to comply with relevant legal requirements, such as the Accounting Act.We may also store information beyond the original purpose if it is necessary for the establishment, exercise or defence of legal claims.</CustomH6>

                <CustomH3><b>3. Security</b></CustomH3>
                <CustomH6>We have implemented appropriate technical and organisational measures ensuring that personal data is processed in a level of security appropriate to the risk, e.g. ensuring confidentiality, availability and integrity of the personal data.</CustomH6>

                <CustomH3><b>4. Recipients of the personal data and the use of subcontractors</b></CustomH3>
                <CustomH6>We do not disclose and/or share your personal data to third parties except where it is necessary for fulfilling our legal obligations or where we have stated otherwise above.For some administrative purposes we use subcontractors (processors). We have secured that the processing is in accordance with the requirements of GDPR by entering into data processing agreements with the data processor. The data processing agreements also ensure that the personal data is not used for any other purpose.Some of our subcontractors, such as OpenAI and Google are be based outside the EU/EEA. We rely on EU Standard Contractual Clauses or that there is an adequacy decision such as the Trans Atlantic Data Privacy Framework to ensure the data is protected when transferred outside the EU/EEA.</CustomH6>

                <CustomH3><b>5. The data subject’s rights</b></CustomH3>
                <CustomH6>As a data subject, you may require- access to,- correction of and/or- deletion of personal data related to the data subjectIf consent is the legal basis for processing of your personal data, you may at any time withdraw your consent by contacting us.You may also have the right to protest to the processing and require data portability. You can always protest on processing with the purpose of direct marketing. If you have any concerns about how we process your personal data, you are welcome to file a complaint to the Norwegian Data Protection Authority (Datatilsynet):<CustomP style={{ marginBottom: '0px' }}>https://www.datatilsynet.no/en/about-us/contact-us/how-to-complain-to-the-norwegian-dpa/ </CustomP> or the relevant data protection authority in your country. We would appreciate if you contact us before filing a complaint.</CustomH6>

                <CustomH3><b>6. Contact information of the data controller</b></CustomH3>
                <CustomH6>If you have any questions on how we process your personal data, or wish to execute one of your data subjects right please contact:<CustomU>lars@simli.com</CustomU></CustomH6>
            </div>
            <Link to="/" className='Link'>
                <button onClick={goBack} className="ButtonPrivacy _HollowPrivacy _ButtonSizePrivacy">Close Privacy Policy!</button>
            </Link>
        </div>
    );
};

export default ScreenPrivacyPolicy;
