import './demo.css';

import React, { useEffect, useState, useCallback } from 'react';
import DailyIframe from '@daily-co/daily-js';
import { DailyProvider } from '@daily-co/daily-react-hooks';

import api from './api';
import { roomUrlFromPageUrl, pageUrlFromRoomUrl } from './utils';

import HomeScreen from './components/HomeScreen/HomeScreen';
import Call from './components/Call/Call';

import x_img from '../../assets/Close-Icon.svg';
import poweredBySimli_img from '../../assets/Powered by.png';
import arrowRight_img from '../../assets/Arrow-Right.svg';
import microphone_img from '../../assets/microphone.png';
import greenCheck_img from '../../assets/green_check.svg';
import microphoneFail from '../../assets/microphone-slash.svg';
import question_img from '../../assets/question.svg';
import stars_img from '../../assets/stars.svg';
import loader_img from '../../assets/Loader.svg';

import { Link } from 'react-router-dom';

/* We decide what UI to show to users based on the state of the app, which is dependent on the state of the call object: see line 137. */
const STATE_IDLE = 'STATE_IDLE';
const STATE_CREATING = 'STATE_CREATING';
const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
const STATE_LEAVING = 'STATE_LEAVING';
const STATE_ERROR = 'STATE_ERROR';

export default function ScreenDemo() {
  const [appState, setAppState] = useState(STATE_IDLE);
  const [roomUrl, setRoomUrl] = useState(null);
  const [callObject, setCallObject] = useState(null);
  const [apiError, setApiError] = useState(false);

  const [showFinished, setShowFinished] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showMicrophonePermission, setShowMicrophonePermission] = useState(false);

  const [firstName, setFirstName] = useState('');

  const [isNameActive, setIsNameActive] = useState(false);
  const [isNameInvalid, setIsNameInvalid] = useState(false);

  const [micPermissionGranted, setMicPermissionGranted] = useState(false);
  const [micPermissionFailure, setMicPermissionFailure] = useState(false);

  const handleNameChange = (e) => {
    const inputName = e.target.value;
    setFirstName(inputName);
  };

  const handleSubmitName = () => {
    if (firstName === '') {
      setIsNameInvalid(true);
      setShowMicrophonePermission(false);
    } else {
      setIsNameInvalid(false);
      setShowMicrophonePermission(true);
    }
  };

  const handleSendFeedback = () => {
    //TODO: send feedback to backend

    setShowFinished(true);
  };

  /**
   * Show the call UI if we're either joining, already joined, or are showing
   * an error.
   */
  const showCall = [STATE_JOINING, STATE_JOINED, STATE_ERROR].includes(
    appState,
  );

  /**
   * Creates a new call room.
   */
  const createCall = useCallback(() => {
    setAppState(STATE_CREATING);
    return api
      .createRoom()
      .then((room) => room.url)
      .catch((error) => {
        console.error('Error creating room', error);
        setRoomUrl(null);
        setAppState(STATE_IDLE);
        setApiError(true);
      });
  }, []);

  /**
   * Starts joining an existing call.
   */
  const startJoiningCall = useCallback((url) => {
    const newCallObject = DailyIframe.createCallObject();
    setRoomUrl(url);
    setCallObject(newCallObject);
    setAppState(STATE_JOINING);
    newCallObject.join({ url });
  }, []);

  // Starting the call is a two-step process: first, we create the call room, then we join it.
  const startCall = () => {
    setShowLoader(true);
    createCall().then((url) => startJoiningCall(url));
  };

  /**
   * Starts leaving the current call.
   */
  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    // If we're in the error state, we've already "left", so just clean up
    if (appState === STATE_ERROR) {
      callObject.destroy().then(() => {
        setRoomUrl(null);
        setCallObject(null);
        setAppState(STATE_IDLE);
      });
    } else {
      /* This will trigger a `left-meeting` event, which in turn will trigger the full clean-up as seen in handleNewMeetingState() below. */
      setAppState(STATE_LEAVING);
      callObject.leave();

      setShowFeedback(true);
    }
  }, [callObject, appState]);

  /**
   * If a room's already specified in the page's URL when the component mounts,
   * join the room.
   */
  useEffect(() => {
    const url = roomUrlFromPageUrl();
    url && startJoiningCall(url);
  }, [startJoiningCall]);

  /**
   * Update the page's URL to reflect the active call when roomUrl changes.
   */
  useEffect(() => {
    const pageUrl = pageUrlFromRoomUrl(roomUrl);
    if (pageUrl === window.location.href) return;
    window.history.replaceState(null, null, pageUrl);
  }, [roomUrl]);

  /**
   * Update app state based on reported meeting state changes.
   *
   * NOTE: Here we're showing how to completely clean up a call with destroy().
   * This isn't strictly necessary between join()s, but is good practice when
   * you know you'll be done with the call object for a while, and you're no
   * longer listening to its events.
   */
  useEffect(() => {
    if (!callObject) return;

    const events = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];

    function handleNewMeetingState() {
      switch (callObject.meetingState()) {
        case 'joined-meeting':
          setAppState(STATE_JOINED);
          break;
        case 'left-meeting':
          callObject.destroy().then(() => {
            setRoomUrl(null);
            setCallObject(null);
            setAppState(STATE_IDLE);
          });
          break;
        case 'error':
          setAppState(STATE_ERROR);
          break;
        default:
          break;
      }
    }

    // Use initial state
    handleNewMeetingState();

    // Listen for changes in state
    for (const event of events) {
      /*
        We can't use the useDailyEvent hook (https://docs.daily.co/reference/daily-react-hooks/use-daily-event) for this
        because right now, we're not inside a <DailyProvider/> (https://docs.daily.co/reference/daily-react-hooks/daily-provider)
        context yet. We can't access the call object via daily-react-hooks just yet, but we will later in Call.js.
      */
      callObject.on(event, handleNewMeetingState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewMeetingState);
      }
    };
  }, [callObject]);


  // Ask for microphone permission
  const askForMicPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      console.log('Microphone permission granted');
      // Do something with the microphone stream if needed
      setMicPermissionGranted(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
      // Handle the error, e.g., display a message to the user
      setMicPermissionFailure(true);
    }
  };

  return (
    <div className="DemoScreen">
      {
        apiError ?
          (
            <div className="api-error">
              <h1>Error</h1>
            </div>
          )
          : showFinished ?
            (
              <div className='feedback-container'>
                <div className='info'>
                  <img src={stars_img} alt='' />
                  <p className='text_big'>Thanks for testing our demo. Please do it again and again and again or...</p>
                  <Link to="/" className="Link"><button className='Button _Fill _ButtonSize2' >Get API-key</button></Link>
                  <Link to="/sign-me-up" className="Link"><button className='Button _Hollow _ButtonSize2' >Fancy Simli updates?</button></Link>
                  <Link to="/" className="Link"><div className='gray_link'>Neither, take me home 🏡</div></Link>
                </div>
              </div>
            )
            : showFeedback ?
              (
                <div className='feedback-container'>
                  <div className='info'>
                    <img src={question_img} alt='' />
                    <p className='text_big'>What could have made the experience better?</p>
                    <textarea
                      className='CustomInput'
                      type="text"
                      placeholder="Write feedback here"
                    />
                    <button className='Button _Fill _ButtonSize2' onClick={handleSendFeedback}>Send feedback</button>
                    <div className='gray_link' onClick={() => setShowFinished(true)}>Skip feedback 😢</div>
                  </div>
                </div>
              )
              : showCall ?
                (
                  <DailyProvider callObject={callObject}>
                    <Call onCancel={startLeavingCall} />
                  </DailyProvider>
                )
                :
                (
                  <div className='initial-container'>
                    <div className='top'>
                      <Link to="/" className="Link"><div className='white_link'>Exit demo</div></Link>
                    </div>
                    {
                      showLoader ?
                      (
                        <div>
                          <img className='loader' src={loader_img} alt='' />
                        </div>
                      )
                      : micPermissionGranted ?
                        (
                          <div className='mic_info'>
                            <div className='micPermissionGranted'>
                              <img src={microphone_img} alt='' />
                              <img src={greenCheck_img} alt='' />
                            </div>
                            <p className='text_big'>To engage in small talk, Jenna needs to be able to hear you. This requires the use of your microphone. 👇</p>
                            <button className='Button _Fill _ButtonSize2 micPermissionGrantedButton' onClick={startCall}>Start small talk</button>
                            <div className='gray_link' onClick={() => {setShowMicrophonePermission(false); setMicPermissionGranted(false);}}>Previous step</div>
                          </div>
                        )
                        : micPermissionFailure ?
                          (
                            <div className='mic_info'>
                              <img src={microphoneFail} alt='' />
                              <p className='text_big'>To engage in small talk, Jenna needs to be able to hear you. This requires the use of your microphone. 👇</p>
                              <button className='Button _Fill _ButtonSize2 micPermissionFailure' onClick={askForMicPermission}>Try again</button>
                              <div className='gray_link' onClick={() => {setShowMicrophonePermission(false); setMicPermissionFailure(false);}}>Previous step</div>
                            </div>
                          )
                          : showMicrophonePermission ?
                            (
                              <div className='mic_info'>
                                <img src={microphone_img} alt='' />
                                <p className='text_big'>To engage in small talk, Jenna needs to be able to hear you. This requires the use of your microphone. 👇</p>
                                <button className='Button _Fill _ButtonSize2' onClick={askForMicPermission}>Ask for access</button>
                                <div className='gray_link' onClick={() => setShowMicrophonePermission(false)}>Previous step</div>
                              </div>
                            )
                            :
                            (
                              <div className='info'>
                                <div>
                                  <b>Your first name</b>
                                  <input
                                    className={`CustomInput ${isNameActive ? '_Active' : (firstName != '' ? '_Valid' : (isNameInvalid ? '_Invalid' : ''))}`}
                                    type="text"
                                    placeholder="First name"
                                    value={firstName}
                                    onFocus={() => setIsNameActive(true)}
                                    onBlur={() => setIsNameActive(false)}
                                    onChange={handleNameChange}
                                  />
                                </div>
                                <button className='Button _Fill _ButtonSize2' onClick={handleSubmitName}>Next<img src={arrowRight_img} alt='' /></button>
                                <div className='gray_link' onClick={() => setShowMicrophonePermission(true)}>Skip this step</div>
                              </div>
                            )
                    }
                    <div className='bottom'><img src={poweredBySimli_img} alt='' /></div>
                  </div>
                )}
    </div>
  );
}
