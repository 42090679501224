import React, { useEffect, useState } from 'react';
import logo from '../../svgs/simli_logo.svg';
import './about-us.css';
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import CloseButton from '../../components/CloseButton';
import Footer from '../../components/Footer/Footer';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import Header from "../../components/Header/Header";


const CustomH3 = styled.h3`
    color: #ffffff; /* White text */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    margin-bottom: 24px; /* Space below paragraphs */
`;
const CustomP = styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word;
`;
const BP = styled.i`
    color: var(--Ice, #00FFF0);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-decoration: none;
`;
const ScreenAboutUs = () => {
    const profiles = [
        {
            image: "./Profiles/ProfilePic (1).png",
            name: "Heidi Frost Eriksen",
            description: "CEO & Co-Founder",
            twitterLink: "https://twitter.com/frost_eriksen",
            linkedinLink: "https://www.linkedin.com/in/frosteriksen/"
        },
        {
            image: "./Profiles/ProfilePic (2).png",
            name: "Lars T. Vågnes",
            description: "CTO & Co-Founder",
            twitterLink: "https://twitter.com/VagnesLars",
            linkedinLink: "https://www.linkedin.com/in/lars-traaholt-v%C3%A5gnes-432725130/"
        },
        {
            image: "./Profiles/ProfilePic (3).png",
            name: "Lene Fredly Rousseau",
            description: "Director of Biz Ops",
            twitterLink: "https://twitter.com/FredlyLene",
            linkedinLink: "https://www.linkedin.com/in/lene-fredly-rousseau-a1371a2b"
        },
        {
            image: "./Profiles/ProfilePic (4).png",
            name: "Marius Vabo",
            description: "Lead Product Designer",
            twitterLink: "https://twitter.com/MariusVabo",
            linkedinLink: "https://www.linkedin.com/in/marius-vabo/"
        },
        {
            image: "./Profiles/ProfilePic (5).png",
            name: "Peter Ulleberg",
            description: "Marketing Manager",
            twitterLink: "https://twitter.com/peterulleberg",
            linkedinLink: "https://www.linkedin.com/in/peter-ulleberg/"
        },
        {
            image: "./Profiles/ProfilePic (W).png",
            name: "Waleed Nizamani",
            description: "Senior SW Developer",
            twitterLink: "https://twitter.com/perceptiveSalt",
            linkedinLink: "https://www.linkedin.com/in/waleed-nizamani-957112104/"
        },
        {
            image: "./Profiles/ProfilePic (7).png",
            name: "Anas Hassan",
            description: "SW Developer",
            twitterLink: "https://twitter.com/AnasMations",
            linkedinLink: "https://www.linkedin.com/in/anas-ahmed-hassan/"
        },
        {
            image: "./Profiles/ProfilePic (Antony).jpg",
            name: "Antony Kiroles",
            description: "SW Developer",
            twitterLink: "https://x.com/AntonyEmil1?t=I3Wh6sjESWpho1ccOGvIFg&s=09",
            linkedinLink: "https://www.linkedin.com/in/antonykiroles"
        },
        {
            image: "./Profiles/ProfilePic (Abo).jpeg",
            name: "Abdelrhman Elrawy",
            description: "ML Engineer",
            twitterLink: "https://twitter.com/a__elrawy",
            linkedinLink: "https://www.linkedin.com/in/elrawy/"
        },
        {
            image: "./Profiles/ProfilePic (10).png",
            name: "Andrea Cicero",
            description: "Technical Artist",
            twitterLink: "https://www.linkedin.com/in/andrea-cicero-50112b175/",
            linkedinLink: "https://www.linkedin.com/in/andrea-cicero-50112b175/"
        }
    ];

     // State to hold the shuffled profiles
     const [shuffledProfiles, setShuffledProfiles] = useState([]);

     // Function to shuffle an array
     const shuffleArray = (array) => {
         for (let i = array.length - 1; i > 0; i--) {
             const j = Math.floor(Math.random() * (i + 1));
             [array[i], array[j]] = [array[j], array[i]];
         }
     }
 
     // useEffect to shuffle the profiles on component mount
     useEffect(() => {
         const shuffled = [...profiles];
         shuffleArray(shuffled);
         setShuffledProfiles(shuffled);
     }, []);
     
    return (
        <div className="AboutUsScreen">
            {/* Header */}
            <Header LeftSide={3} RightSide={1} Style = {2} TextButton = 'The team'></Header>
            {/* Content */}
            <p style={{marginTop: '-75px'}}></p>
            <div className="AboutUsScreen-content">
                <CustomH3 style={{marginBottom:'160px'}}><b></b></CustomH3>      

                <CustomH3 className = "Width345px"><b>Hello 👋</b></CustomH3>
                <CustomP className = "Width345px">Founded in 2021 by serial entrepreneurs and backed by venture capital, we’re a dynamic team of 10 with strong AI, commercial, and design backgrounds. Our mission? Making interactions with AI as human-like as possible.</CustomP>

            </div>
            <div className = "ProfileCards">
                {shuffledProfiles.map(profile => (
                    <ProfileCard
                        key={profile.name} // using name as key, assuming it's unique
                        image={profile.image}
                        name={profile.name}
                        description={profile.description}
                        twitterLink={profile.twitterLink}
                        linkedinLink={profile.linkedinLink}
                    />
                ))}
            </div>
            <Footer MarginTopDesktop={'92px'} MarginTopMobile={'82px'}></Footer>
        </div>
    );
};

export default ScreenAboutUs;
