import './HelloButton.css';
import React, { useState, useEffect } from 'react';

const HelloButtonAutomatic = ({ children }) => {
    // Styles to choose from
    const styleList = [
        { backgroundColor: '#FFFF00', borderRadius: '50%' }, // Style 1
        { backgroundColor: '#00FF00', borderRadius: '0' }, // Style 2
        { backgroundColor: '#00FFF0', borderRadius: '100px' }, // Style 3
        { backgroundColor: '#FF00FF', borderRadius: '8px' }, // Style 4
        { backgroundColor: '#FF0000', borderRadius: '50%', color: 'white' }, // Style 5
        { backgroundColor: '#B198F6', borderRadius: '0' }, // Style 6
        { backgroundColor: '#8900DA', borderRadius: '0', color: 'white' },  // Style 7
    ];

    const [currentStyleIndex, setCurrentStyleIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentStyleIndex(prevIndex => (prevIndex + 1) % styleList.length);
        }, 300); // Corrected to 300 milliseconds (0.3 seconds)

        // Cleanup function to clear interval when component unmounts
        return () => clearInterval(intervalId);
    }, [styleList.length]);

    return (
        <button
            className='HelloButtonAutomatic'
            style={styleList.length > 0 ? styleList[currentStyleIndex] : {}}
        >
            <b>{children}</b>
        </button>
    );
};

export default HelloButtonAutomatic;
