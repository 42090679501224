import './Header.css';
import HelloButton from '../HelloButton/HelloButton';
import HelloButtonAutomatic from '../HelloButton/HelloButtonAutomatic';
import CloseButton from '../CloseButton';
import logo from '../../svgs/simli_logo.svg';
import logo_back from '../../svgs/simli_ArrowBack.svg';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';

const Header = ({LeftSide, RightSide, Style, TextButton}) => {

    const [LeftItem, setLeftSide] = useState(LeftSide);
    const [RightItem, setRightSide] = useState(RightSide);

    const ListOfHeaderItems = [
        <Link to='/'><img src={logo} className='Logo' alt="simli logo" /></Link>,
        <HelloButton style={Style}> {TextButton}</HelloButton>,
        <Link to='/' className='Link'><CloseButton></CloseButton></Link>,
        <Link to='/'><img src={logo_back} className='LogoArrow' alt="simli logo" /></Link>,
        <HelloButtonAutomatic>{TextButton}</HelloButtonAutomatic>,
    ]
    return (
        <div className="Header">
            {ListOfHeaderItems[LeftItem]}
            {ListOfHeaderItems[RightItem]}
        </div>
    );
};
export default Header;