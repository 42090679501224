import './HelloButton.css';
import React, { useState } from 'react';

const HelloButton = ({ children, style = 1 }) => {
    // Styles to choose from
    const styleList = [
        { backgroundColor: '#FFFF00', borderRadius: '50%' }, // Style 1
        { backgroundColor: '#00FF00', borderRadius: '0' }, // Style 2
        { backgroundColor: '#00FFF0', borderRadius: '100px' }, // Style 3
        { backgroundColor: '#FF00FF', borderRadius: '8px' }, // Style 4
        { backgroundColor: '#FF0000', borderRadius: '50%', color: 'white' }, // Style 5
        { backgroundColor: '#B198F6', borderRadius: '0' }, // Style 6
        { backgroundColor: '#8900DA', borderRadius: '0', color: 'white' },  // Style 7
    ];

    const [currentStyleIndex, setCurrentStyleIndex] = useState(style - 1);

    const handleClick = () => {
        setCurrentStyleIndex((currentStyleIndex + 1) % styleList.length);
    };

    return (
        <button
            className='HelloButton'
            style={styleList[currentStyleIndex]}
            onClick={handleClick}
        >
            <b>{children}</b>
        </button>
    );
};

export default HelloButton;
